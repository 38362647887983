import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/default"

import Breadcrumb from "../../../components/breadcrumb"
import Constrain from "../../../components/constrain"
import Paragraph from "../../../components/paragraph"
import HyperCardMini from "../../../components/hyper-card-mini"
import PageTitle from "../../../components/page-title"
import Seo from "../../../components/seo"
import Stack from "../../../components/stack"

const LeichteSpracheBesuch = () => {
  const data = useStaticQuery(graphql`
    query LeichteSpracheBesuchIndexQuery {
      mediaguide: file(relativePath: { eq: "leichte-sprache/mediaguide.jpg" }) {
        ...smallImage
      }
      fuehrungen: file(relativePath: { eq: "leichte-sprache/fuehrungen.jpg" }) {
        ...smallImage
      }
      oeffnungszeiten: file(
        relativePath: { eq: "leichte-sprache/oeffnungszeiten.jpg" }
      ) {
        ...smallImage
      }
      essen: file(relativePath: { eq: "leichte-sprache/essen-la-torre.jpg" }) {
        ...smallImage
      }
      garderobe: file(relativePath: { eq: "leichte-sprache/garderobe.jpg" }) {
        ...smallImage
      }
      wegbeschreibung: file(
        relativePath: { eq: "leichte-sprache/wegbeschreibung.jpg" }
      ) {
        ...smallImage
      }
    }
  `)

  return (
    <Layout backdrop="aussen">
      <Seo
        title="Mein Besuch im Hölderlin∙turm"
        description="Hölderlin wird am 20. März 1770 geboren. Sein ganzer Name ist Johann Christian Friedrich Hölderlin. Er kommt in der Stadt Lauffen am Neckar zur Welt."
      />
      <Stack space={[12, 24]}>
        <Stack>
          <Breadcrumb
            items={[
              {
                title: "Leichte Sprache",
                link: "/leichte-sprache",
              },
              {
                title: "Besuch",
                link: "/leichte-sprache/besuch",
              },
            ]}
          />
          <PageTitle>Mein Besuch im Hölderlin∙turm</PageTitle>
          <Paragraph dropcap={true}>
            Sie können den Hölderlin∙turm alleine besuchen. <br />
            Sie können den Turm auch mit einer Gruppe besuchen.
            <br />
            Wir freuen uns auf Ihren Besuch!
            <br />
            Der Eintritt in unser Museum kostet nichts.
          </Paragraph>
          <Paragraph>
            In der Dauer∙ausstellung gibt es Texte in Leichter Sprache. <br />
            Die Texte können Sie hören und lesen. <br />
            Es gibt auch Führungen in Leichter Sprache. <br />
            Auf diesen Seiten finden Sie alle Informationen dazu.
          </Paragraph>
        </Stack>

        <Constrain>
          <Stack space={6}>
            <HyperCardMini
              title="Öffnungszeiten und Eintritt"
              image={data.oeffnungszeiten}
              to="/leichte-sprache/besuch/oeffnungszeiten"
              attribution="David Franck"
              cta="Mehr lesen"
            />
            <HyperCardMini
              title="Media-Guide in Leichter Sprache"
              image={data.mediaguide}
              to="/leichte-sprache/besuch/mediaguide"
              cta="Mehr lesen"
            />
            <HyperCardMini
              title="Führungen in Leichter Sprache"
              image={data.fuehrungen}
              to="/leichte-sprache/besuch/fuehrungen"
              attribution="David Franck"
              cta="Mehr lesen"
            />
            <HyperCardMini
              title="So kommt man zum Hölderlin∙turm"
              image={data.wegbeschreibung}
              to="/leichte-sprache/besuch/wegbeschreibungen"
              attribution="David Franck"
              cta="Mehr lesen"
            />
            <HyperCardMini
              title="Diese Regeln gibt es im Hölderlin∙turm"
              image={data.garderobe}
              to="/leichte-sprache/besuch/regeln"
              attribution="David Franck"
              cta="Mehr lesen"
            />
            <HyperCardMini
              title="Essen und Trinken in der Nähe"
              image={data.essen}
              to="/leichte-sprache/besuch/essen-und-trinken"
              attribution="La Torre Pizzeria"
              cta="Mehr lesen"
            />
          </Stack>
        </Constrain>
      </Stack>
    </Layout>
  )
}

export default LeichteSpracheBesuch
